<template>
  <section class="w-full bg-white">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3  pl-4 pr-4 pt-4 pb-2 gap-2 lg:mb-0">
      <div class="field">
        <label for="icon">Fecha inicial</label>
        <input v-model="filters.fechaInicial"
               class="p-button-secondary w-full border p-1.5 rounded-md mb-1 mt-1 flex-items-center" type="date">
      </div>
      <div class="field">
        <label for="icon">Fecha final</label>
        <input v-model="filters.fechaFinal"
               class="p-button-secondary w-full border p-1.5 rounded-md mb-1 mt-1 flex-items-center" type="date">
      </div>
      <div style="align-self: center;" class="field">
        <label for="nombre">Proveedor</label>
        <div>
          <Dropdown
            v-model="filters.proveedor"
            :options="proveedores"
            @click="$h.xxsInput"
            class="rounded-md border-gray-300 w-full p-inputtext-xs"
            panelClass="text-xxs"
            optionLabel="CardName"
            :showClear="true"
            optionValue="CardCode"
            :filter="true"
            placeholder="Seleccione un proveedor"/>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-3 pl-4 pb-6 gap-4 pr-4 lg:mb-0">
      <div class="field grid">
        <label>Número factura</label>
        <Chips
          id="numFactura"
          v-model="filters.numFactura"
          :allowDuplicate="false"
          separator=","
          inputClass="p-inputtext-sm"
          class="text-xs p-inputtext-xs w-full"
          placeholder="Facturas separadas por ,"
        />
      </div>
    </div>
    <div class="flex gap-4 pr-4 mb-4 lg:mb-0 justify-end pb-4">
      <div class="pb-2 flex">
        <Button class="border-0" @click="searchFilters" label="Buscar"/>
      </div>
      <div class="pb-2 flex">
        <Button class="border-0 p-button-outlined p-button-secondary" @click="clearFilters" label="Limpiar"/>
      </div>
    </div>
  </section>
  <div class="pt-6">
    <DataTable
      :value="recepcionesList"
      class="p-datatable-xs text-xs"
      dataKey="DocNum"
      filterDisplay="menu"
      responsiveLayout="scroll"
    >
      <template #empty>
        No hay información de XML cargados.
      </template>
      <Column field="id" header="N° R. Administrativa" headerStyle="text-align: center;justify-content: center;"
              bodyStyle="text-align: center;justify-content: center;">
        <template #body="{data}">
          <Button :label="`${data.id}`"
                  class="p-button-link text-black"
                  style="font-size: 0.75rem"
                  @click="openModalDetails(data.id)"
          />
        </template>
      </Column>
      <Column field="NumFactura" bodyStyle="text-align: center;justify-content: center;"
              headerStyle="text-align: center;justify-content: center;" header="N° Fctura">
        <template #body="{data}">
          <div class="lg:text-xs">
            {{ data.NumFactura }}
          </div>
        </template>
      </Column>
      <Column field="DocDate" header="Fecha Factura" headerStyle="text-align: center;justify-content: center;"
              bodyStyle="text-align: center;justify-content: center;"/>
      <Column field="CardName" header="Proveedor" headerStyle="text-align: center;justify-content: center;"
              bodyStyle="text-align: center;justify-content: center;"/>
      <Column field="TaxTotal" bodyStyle="text-align: center;justify-content: center;"
              headerStyle="text-align: center;justify-content: center;" header="Total IVA">
        <template #body="{data}">
          <div class="lg:text-xs">
            {{ $h.formatCurrency(data.TaxTotal) }}
          </div>
        </template>
      </Column>
      <Column field="DiscTotal" bodyStyle="text-align: center;justify-content: center;"
              headerStyle="text-align: center;justify-content: center;" header="Total Descuento">
        <template #body="{data}">
          <div class="lg:text-xs">
            <p>
              {{ $h.formatCurrency(data.DiscTotal) }}
            </p>
          </div>
        </template>
      </Column>
      <Column field="DocTotal" bodyStyle="text-align: center;justify-content: center;"
              headerStyle="text-align: center;justify-content: center;" header="Total Factura">
        <template #body="{data}">
          {{ $h.formatCurrency(data.DocTotal) }}
        </template>
      </Column>
      <Column field="" bodyStyle="text-align: center;justify-content: center;"
              headerStyle="text-align: center;justify-content: center;" header="Acciones">
        <template #body="{data}">
          <Button icon="pi pi-pencil" v-tooltip.top="'Editar'"
                  iconClass="text-xl"
                  @click="editarRecepcionAdmin(data.id)"
                  class="p-button-text p-button-warning">
          </Button>
          <Button icon="pi pi-trash" v-tooltip.top="'Eliminar'"
                  iconClass="text-xl"
                  @click="deleteRecepcion(data.id)"
                  class="p-button-text p-button-danger">
          </Button>
        </template>
      </Column>
    </DataTable>
    <Paginator v-model:first="offset"
               :rows="limit"
               :totalRecords="totalRecords"
               :rowsPerPageOptions="[1,10,20,30,50]"
               :pageLinkSize="pageLinkSize"
               @page="onPage($event)"
    />
  </div>
  <!--  <Card>-->
  <!--    <template #content>-->
  <!--      <div class="pt-2 flex justify-end">-->
  <!--        <Button-->
  <!--          v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-pedidos.copiar-a-recepcion')"-->
  <!--          label="Copiar a recepción"-->
  <!--          icon="pi pi-copy"-->
  <!--          class="p-button-raised p-button-success floating-button p-button-rounded"-->
  <!--          @click="onCreate"-->
  <!--        />-->
  <!--      </div>-->
  <!--    </template>-->
  <!--  </Card>-->
  <div>
    <modalDetails ref="openModalDet"/>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed, defineAsyncComponent } from 'vue'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'

export default {
  name: 'listarXml',
  components: {
    modalDetails: defineAsyncComponent(() =>
      import('./modalXmlDetallado.vue')
    )
  },
  setup () {
    const _RecepcionPedidosService = new RecepcionPedidosService()
    const openModalDet = ref()
    const router = useRouter()
    const offset = ref(0)
    const page = ref(0)
    const recepcionesList = ref([])
    const limit = ref(10)
    const totalRecords = ref(0)
    const pageLinkSize = ref(5)
    const filters = reactive({
      proveedor: null,
      numFactura: [],
      numOfertas: [],
      fechaFinal: null,
      fechaInicial: null
    })
    const params = computed(() => {
      return {
        offset: offset.value,
        page: page.value,
        limit: limit.value,
        fechaInicial: filters.fechaInicial,
        fechaFinal: filters.fechaFinal,
        numFactura: filters.numFactura,
        numOfertas: filters.numOfertas,
        proveedor: filters.proveedor
      }
    })
    const openModalDetails = (id) => {
      _RecepcionPedidosService.getDetailsXml(id).then(({ data }) => {
        openModalDet.value.openModal(data)
      })
    }
    const deleteRecepcion = (id) => {
      Swal.fire({
        icon: 'question',
        title: '¿Esta seguro/a?',
        text: '¿Desea eliminar esta recepción administrativa?',
        showConfirmButton: true,
        focusCancel: true,
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'No, cancelar'
      }).then((resp) => {
        if (resp.isConfirmed) {
          _RecepcionPedidosService.deleteRecepcionAdmin(id).then(({ data }) => {
            if (data.id) {
              Swal.fire({
                icon: 'success',
                title: 'Éxito',
                text: `Se eliminó la recepción administrativa Número: ${data.id} exitosamente`,
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true
              }).then((response) => {
                getPaginate()
              })
            }
          })
        }
      })
    }
    const editarRecepcionAdmin = (id) => {
      router.push({ name: 'pharmasan.compras.logistica.recepcion-pedidos.xml', params: { idRecepcionAdmin: id } })
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      getPaginate()
    }
    const searchFilters = () => {
      getPaginate()
    }
    const clearFilters = () => {
      filters.proveedor = null
      filters.numFactura = []
      getPaginate()
    }
    const proveedores = ref([])
    const getPaginate = async () => {
      await _RecepcionPedidosService.paginateXml(params.value).then(({ data }) => {
        recepcionesList.value = data
        // totalRecords.value = data.rows[0].countRows
      })
    }
    const getProveedores = () => {
      _RecepcionPedidosService.getProveedores().then(({ data }) => {
        proveedores.value = data
      })
    }
    onMounted(() => {
      const date = new Date()
      const now = new Date()
      const lastMonth = date.setMonth(date.getMonth() - 1)
      filters.fechaInicial = dayjs(lastMonth).format('YYYY-MM-DD')
      filters.fechaFinal = dayjs(now).format('YYYY-MM-DD')
      getPaginate()
      getProveedores()
    })
    return {
      // selectedCustomers,
      filters,
      proveedores,
      // getPaginate,
      getProveedores,
      openModalDetails,
      openModalDet,
      params,
      onPage,
      page,
      offset,
      limit,
      deleteRecepcion,
      pageLinkSize,
      totalRecords,
      searchFilters,
      clearFilters,
      getPaginate,
      recepcionesList,
      editarRecepcionAdmin
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.p-multiselect-label ) {
  display: flex !important;
  flex-wrap: wrap !important;
}

.p-chips-token {
  padding: 0.1rem 0.5rem;
  margin-right: 0.4rem;
  margin-top: 2px;
  margin-bottom: 2px;
}

::v-deep(.p-chips-multiple-container) {
  padding: 0.1rem 0.6rem;
  max-height: 70px;
  overflow-y: auto;
}

::v-deep(.p-multiselect-label-container) {
  overflow-y: auto !important;
  max-height: 75px !important;
}

::v-deep(.country-item) {
  margin: 2px !important
}

::v-deep(.multiselect-custom) {
  .p-multiselect-label:not(.p-placeholder) {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .country-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .5rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }
}

.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #71717A;
  width: 100rem;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

.floating-button {
  position: fixed !important;
  bottom: 35px;
  right: 40px;
  z-index: 1050;
}
</style>
